import { has } from 'lodash-es'
import type { ServerNotifyMessagePayload } from '#core/types/packages/socket'
import type { SectionItem, SectionSyncPayload } from '#section/types'
import { SECTION_LOADER_FRAGMENT } from '#section/loader_fragment'
import { BOARD_CACHE, ModelListCache } from '#core/cache'
import { SECTION_TYPE_NAME } from '#section/constant'
import { TaskLevel } from '#task/constant'

class SectionListCache extends ModelListCache<SectionItem> {
  constructor() {
    super(SECTION_TYPE_NAME)
  }

  override getBoardCacheKeys(): BOARD_CACHE[] {
    return [BOARD_CACHE.SECTION]
  }
}

export const useSectionSync = () => {
  const { client } = useApolloClient()
  const { currentBoard } = useWorkspaceSharedState()
  const listCache = new SectionListCache()
  const { getSectionIdentifier, getSyncSectionFields } = useSectionDataConvert()

  const onAdd = async (payload: SectionSyncPayload) => {
    client.cache.writeFragment({
      data: payload,
      fragment: SECTION_LOADER_FRAGMENT,
      fragmentName: 'SectionLoader',
    })

    // Add the new item to the list cache
    listCache.add(currentBoard.value.id, payload)
  }

  const onUpdate = (payload: SectionSyncPayload) => {
    // If section is reopened, treat it like a new section
    if (has(payload, 'closed') && payload.closed === false) {
      onAdd(payload)
      return
    }

    client.cache.modify({
      id: getSectionIdentifier(payload.id),
      fields: getSyncSectionFields(payload),
    })

    if (payload.statusId) {
      const boardTasks = useReadTasksFromCache(currentBoard.value.id)
      const sectionTasks = boardTasks.filter(
        (task) => task.sectionId === payload.id && task.level !== TaskLevel.SUBTASK
      )
      if (!sectionTasks.length) {
        return
      }

      const { getTaskIdentifier } = useTaskDataConvert()
      Promise.all(sectionTasks.map((task) => {
        client.cache.modify({
          id: getTaskIdentifier(task.id),
          fields: {
            statusId: () => payload.statusId,
          },
        })
      }))
    }

    // Update the item in the list cache
    listCache.update(currentBoard.value.id, payload)
  }

  const onDelete = (payload: SectionSyncPayload) => {
    client.cache.evict({
      id: getSectionIdentifier(payload.id),
    })
  }

  const sync = (payload: ServerNotifyMessagePayload) => {
    const eventRegister = new Map<
      ServerNotifyMessagePayload['action'],
      (payload: SectionSyncPayload) => void
        >([
          ['ADD', onAdd],
          ['UPDATE', onUpdate],
          ['DELETE', onDelete],
        ])

    const eventHandler = eventRegister.get(payload.action)
    eventHandler?.(payload.model)
  }

  return {
    sync,
    listCache,
  }
}
